import React, { useState, useEffect } from 'react';
import Input from 'components/Input';
import { objectToQueryString, api } from 'helpers/FetchHelper';
import { Fighter } from 'models/Fighter';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { debounce } from 'underscore';
import { LoadingStates } from 'utils/Enums';
import { LoadingIndicator } from 'components/shared/LoadingIndicator';
import FighterCell from './FighterCell';

type FightersSearchProp = {
  trendingFighters: Fighter[];
};

const FighterSearch = ({ trendingFighters }: FightersSearchProp): JSX.Element => {
  const [searchString, setSearchString] = useState('');
  const [message, setMessage] = useState('');
  const [fighterSearchResult, setFighterSearchResult] = useState(trendingFighters);
  const [loadingState, setLoadingState] = useState(LoadingStates.PRE);

  useEffect(() => {
    onSearch(searchString);
  }, [searchString]);

  const onSearch = async (searchString: string) => {
    setLoadingState(LoadingStates.LOADING);
    if (!searchString) {
      setFighterSearchResult(trendingFighters);
      setMessage('');
    } else if (searchString && searchString.length < 3) {
      setFighterSearchResult([]);
      setMessage('Type three characters to search for a fighter');
    } else {
      const queryString = objectToQueryString({ searchString, includeFighterDisplayModel: true });
      const response = await api('GET', `/api/v1/fighters${queryString}`);
      const json = await response.json();
      if (json && json.fighters && json.fighters.length > 0) {
        setFighterSearchResult(json.fighters);
        setMessage('');
      } else {
        setFighterSearchResult([]);
        setMessage('No fighters found');
      }
    }
    setLoadingState(LoadingStates.SUCCESS);
  };

  const debounceSearchChange = debounce((searchString) => {
    setSearchString(searchString);
  }, 500);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="w-full">
        <div className="flex flex-row items-center border-b border-gray-200">
          <div className="w-full flex flex-row my-4 mx-4 items-center rounded bg-gray-50 border border-gray-300">
            <Input
              data-test="search"
              type="text"
              placeholder="Search for a fighter..."
              className={'border-0 border-transparent bg-transparent placeholder:text-sm placeholder:text-gray-500'}
              onChange={(e) => {
                e.stopPropagation();
                e.currentTarget.focus();
                debounceSearchChange(e.target.value);
              }}
            />
            <MagnifyingGlassIcon className="h-5 w-5 mr-3 text-gray-500" />
          </div>
        </div>
        {loadingState == LoadingStates.LOADING ? (
          <div className="w-full p-4">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="gap-2 divide-y divide-gray-200">
            {message && (
              <div className="w-full p-4 text-center ">
                <div className="text-md font-medium font-sans text-gray-800 leading-none">{message}</div>
              </div>
            )}
            {fighterSearchResult &&
              fighterSearchResult.map((fighter, index) => (
                <div className="flex flex-col" key={index}>
                  <FighterCell fighter={fighter} hasBorder={false} />
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FighterSearch;
